<!--交易流水详情-->
<template>
    <div v-loading="loading" style="padding:0 12px">
        <el-row :gutter="20">
            <el-col :span="24" class="titleContent">
                <span class="title iconTitle"><i></i>基础信息</span>
            </el-col>
            <el-col :span="8" class="item">
                <span>交易流水号:</span>
                <span>{{ info.reqSeqId }}</span>
            </el-col>
            <el-col :span="8" class="item">
                <span>汇付全局流水号:</span>
                <el-tooltip effect="dark" :content="info.hfSeqId" placement="bottom">
                    <span>{{ info.hfSeqId }}</span>
                </el-tooltip>
            </el-col>
            <el-col :span="8" class="item" v-if="type == 2">
                <span>关联行程单:</span>
                <el-button type="text" @click="queryEventLogDetail(info.eventLogNo)">{{ info.eventLogNo }} <i
                        class="aaac">?</i>
                </el-button>
            </el-col>
            <el-col :span="8" class="item">
                <span>交易类型:</span>
                <span :style="{ 'color': (type == 2 ? '#4F4D46' : '#2C6AFF') }">{{ info.businessTypeName }}</span>
            </el-col>
            <el-col :span="8" class="item" v-if="type == 2">
                <span>支付方式:</span>
                <span>{{ info.paymentTypeName }}</span>
            </el-col>
            <el-col :span="8" class="item">

                <span>交易状态:</span>
                <span v-if="info.transactionStatusName"
                    :style="{ 'color': info.transactionStatusName.includes('成功') ? '#6BCA35' : info.transactionStatusName.includes('失败') ? '#FF4D4F' : '#2C6AFF' }">{{
                            info.transactionStatusName
                    }}</span>
            </el-col>
            <el-col :span="8" class="item">
                <span>付款方:</span>
                <span v-show="info.payerName">{{ info.payerName + ' ' + info.payerNumber }}</span>
            </el-col>
            <el-col :span="8" class="item">

                <span>收款方:</span>
                <span v-show="info.payeeName">{{ info.payeeName + ' ' + info.payeeNumber }}</span>
            </el-col>
            <el-col :span="8" class="item">

                <span>交易金额(元):</span>
                <span style="color:#FA8C16" v-show="info.type">{{
                        info.transactionAmount
                }}</span>
            </el-col>
            <el-col :span="8" class="item">
                <span>交易时间:</span>
                <span>{{ info.opTimeStr }}</span>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="24" class="titleContent">
                <span class="title iconTitle"><i></i>手续费</span>
            </el-col>
            <el-col :span="8" class="item">

                <span>手续费收费方式:</span>
                <span>{{ info.feeFlagName }}</span>
            </el-col>
            <el-col :span="8" class="item">

                <span>手续费金额(元):</span>
                <span>{{ info.feeAmount }}</span>
            </el-col>
            <el-col :span="8" class="item">

                <span>手续费扣款商户名称:</span>
                <span>{{ info.outFeeHuifuName }}</span>
            </el-col>
            <el-col :span="8" class="item">

                <span>手续费扣款商户号:</span>
                <span>{{ info.outFeeHuifuid }}</span>
            </el-col>
        </el-row>
        <el-row :gutter="20" v-if="type == 2">
            <el-col :span="24" class="titleContent">
                <span class="title iconTitle"><i></i>分账信息</span>
            </el-col>
            <el-col :span="24" class="item">
                <el-table :data="info.acctSplitBunchVOList" :header-cell-style="headClass" :loading="loading"
                    style="width:100%">
                    <el-table-column prop="name" label="分账接收方名称"></el-table-column>
                    <el-table-column prop="huifuId" label="分账接收方商户号"></el-table-column>
                    <el-table-column label="分账金额(元)">
                        <template slot-scope="scope">
                            <span style="color:#FA8C16">{{
                                    scope.row.divAmt
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="opTimeStr" label="分账时间"></el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-dialog title="行程记录" :visible.sync="dialogVisible" width="714px">
            <el-descriptions :column="2">
                <el-descriptions-item label="行程单号">{{ eventLogDetail.no }}</el-descriptions-item>
                <el-descriptions-item label="车牌号">{{ eventLogDetail.carNum }}</el-descriptions-item>
                <el-descriptions-item label="司机姓名">{{ eventLogDetail.driverName }}</el-descriptions-item>
                <el-descriptions-item label="司机电话">{{ eventLogDetail.driverMobile }}</el-descriptions-item>
                <el-descriptions-item label="乘客姓名">{{ eventLogDetail.userName }}</el-descriptions-item>
                <el-descriptions-item label="乘客联系电话">{{ eventLogDetail.mobile }}</el-descriptions-item>
                <el-descriptions-item label="下车时间">{{ eventLogDetail.endTimeStr }}</el-descriptions-item>
                <el-descriptions-item label="下车地点">{{ eventLogDetail.destination }}</el-descriptions-item>
            </el-descriptions>
        </el-dialog>
    </div>
</template>

<script>
import listPage from "../../base/listPage.vue";
import TripFinancialApi from "@/api/TripFinancialApi";
export default {
    extends: listPage,
    data() {
        return {
            info: {
                name: "11"
            },
            type: 2,
            eventLogDetail: {},
            eventLogLoading: true,
            dialogVisible: false,
        }
    },
    watch: {
        $route(to) {
            if (to.name.includes('transactionRecordDetails'))
                this.init();
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            let obj = this.$route.params.id;
            let id = obj.split('_')[0];
            this.type = obj.split('_')[1];
            if (!id) {
                this.$errorMsg('请求参数不正确');
                return;
            }
            this.findTransactionRecordDetail(id, this.type);
        },
        headClass() {
            return "background:#FAFAFA;color:#666666";
        },
        async findTransactionRecordDetail(id, type) {
            this.loading = true;
            let resp;
            if (type == 2)
                resp = await TripFinancialApi.findTransactionRecordDetail({ id: id })
            else
                resp = await TripFinancialApi.findSettleRecordDetail({ id: id })
            // console.log(resp);
            if (resp.code === '200') {
                this.loading = false;
                this.info = resp.data;
            }
        },
        queryEventLogDetail(no) {
            this.dialogVisible = true;
            this.eventLogLoading = true;
            TripFinancialApi.queryEventLogDetail(no).then((resp) => {
                if (resp.code === '200') {
                    this.eventLogLoading = false;
                    this.eventLogDetail = resp.data;
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .item {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 14px;
    display: flex;
    color: #4f4d46;

    >span {
        min-width: 140px;
        text-align: left;
        display: inline-block;
        color: #666666;
        vertical-align: top;
        line-height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .el-button {
        padding: 0;
    }
}

.titleContent {
    font-size: 16px;
    line-height: 32px;
    height: 32px;
    margin-top: 16px;

    .iconTitle {
        height: 32px;
        line-height: 32px;
        color: #4f4d46;
        font-weight: bold;
    }

    i {
        display: inline-block;
        width: 4px;
        height: 16px;
        background: #2c6aff;
        margin-right: 8px;
        margin-top: 5px;
    }
}

::v-deep .el-row {
    line-height: 12px;
}

::v-deep .titleContent .el-button {
    float: right;
    height: 40px;

    span {
        color: white;
    }
}

.aaac {
    font-size: 12px;
    display: inline-block;
    padding: 0;
    line-height: 16px;
    margin-left: 8px;
    height: 16px;
    width: 16px;
    font-style: normal;
    color: #d5d5d5;
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
}

::v-deep .el-dialog {
    margin-top: calc(50vh - 105px) !important;

    .el-dialog__header {
        padding: 16px 24px;
        font-weight: bold;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
    }

    .el-dialog__body {
        padding: 8px 24px 10px 24px;
    }
}
</style>